<script setup>
import { usePage, Head, Link } from '@inertiajs/vue3';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import axios from 'axios';
import { loadLanguageAsync, trans } from 'laravel-vue-i18n';
import { getCurrentInstance, ref, watch } from 'vue';

const page = usePage()
const locale = page.props.locale;
const instance = getCurrentInstance();
const global = instance.appContext.config.globalProperties
const isLoggedIn = page.props.auth.user !== null;
const showMenu = ref(false);

const props = defineProps({
    show: {
        type: String,
        default: 'learn-more',
        required: true,
    }
});

const toggle = ref(false);

const emit = defineEmits(['scrollTo', 'toggle'])

function scrollTo(id) { 
    emit('scrollTo', id)
}

function changeToggle(id) {    
    toggle.value = !toggle.value;
    emit('toggle', id, toggle.value)
}

watch(() => props.show, (val) => {
    toggle.value = val.length
}, {
    immediate: true 
});

function changeLanguage()
{
    axios.post(route('change-language'))
    .then(res => {
        window.location.reload()
    }).catch(error => {
        console.log(error);
    });

} 
</script>

<template>
    <nav class="border-gray-200">
        <div class="max-w-screen-xl flex flex-wrap sm:flex-nowrap justify-between md:justify-normal items-center mx-auto p-4">
            <Link href="/" class="items-center space-x-2 rtl:space-x-reverse">
                <span class="self-center text-xs font-semibold whitespace-nowrap text-white">{{ trans('Excitement in') }}</span>
                <img src="/assets/images/escape_logo.png" alt="escape_logo.png" class="h-12">
            </Link>
            <button @click="showMenu = (showMenu ? false : true) " data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div class="w-full md:block md:w-full" :class="!showMenu ? 'hidden' : ''"id="navbar-default">
                <div class="block w-auto md:flex md:justify-between md:items-center" :class="showMenu ? 'bg-gray-800' : 'dark:border-gray-700'">
                    <div class="">
                        <ul class="font-medium flex flex-col items-center p-4 md:p-0 mt-4 text-white rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0  rtl:space-x-reverse">
                            <li>
                                <Link :href="route('welcome')" class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold" aria-current="page">{{ trans('Home') }}</Link>
                            </li>
                            <li>
                                <Link :href="route('welcome') + '/#rooms'" v-smooth-scroll @click="scrollTo('rooms')" class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold" aria-current="page">{{ trans('Rooms') }}</Link>
                            </li>
                            <li v-if="page.component != 'Guest/BookRoom'">
                                <button @click="changeToggle('learn-more')" class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold" aria-current="page">{{ trans('Learn more') }}</button>
                            </li>
                            <li v-if="page.component != 'Guest/BookRoom'">
                                <button @click="changeToggle('winners')" v-smooth-scroll class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold" aria-current="page">{{ trans('Winners') }}</button>
                            </li>
                            <template v-if="page.props.auth.user && page.props.auth.user.role.id != 4">
                                <li v-if="isLoggedIn">
                                    <Link :href="route('admin.dashboard')" target="_blank" class="flex justify-center items-center py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold">
                                        <span>{{ trans('Dashboard') }}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3.5 h-3.5 mb-1 ml-1">
                                            <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd"></path>
                                            <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd"></path>
                                        </svg>
                                    </Link>
                                </li>
                            </template>
                            <li>
                                <button @click="changeLanguage" v-if="locale == 'ar'" class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold">
                                    <div class="flex space-x-1 rtl:space-x-reverse">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
                                            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
                                            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
                                        </svg>
                                        <span>EN</span>
                                    </div>
                                </button>
                                <button @click="changeLanguage" v-else class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold">
                                    <div class="flex space-x-1 rtl:space-x-reverse">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
                                            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
                                            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
                                        </svg>
                                        <span>AR</span>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="flex justify-center">

                        <div class="grid md:flex justify-center items-center space-x-2 rtl:space-x-reverse">
                            <button v-if="!page.props.auth.user" @click="changeToggle('login')" class="sm:flex justify-between items-center border border-white/50 hover:border-white text-white/50 hover:text-white rounded-lg p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                </svg>
                            </button>
                            <div class="sm:hidden" v-else>
                                <DropdownLink :href="route('user.profile')" class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold"> {{ $t('Profile') }} </DropdownLink>
                                <DropdownLink :href="route('logout')" method="post" as="button" class="block py-2 pl-3 pr-4 text-white hover:text-slate-200 font-bold">
                                    {{ $t('Log Out') }}
                                </DropdownLink>
                            </div>
                            <button @click="changeToggle('search')" class="flex justify-center items-center text-white/50 hover:text-white rounded-lg p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                </svg>
                            </button>
                        </div>
                        <template v-if="page.props.auth.user">
                            <div class="hidden sm:flex sm:items-center">
                                <!-- Settings Dropdown -->
                                <div class="ms-3 relative">
                                    <Dropdown align="right" width="48">
                                        <template #trigger>
                                            <span class="inline-flex rounded-md">
                                                <button
                                                    type="button"
                                                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none transition ease-in-out duration-150"
                                                >
                                                <div class="flex justify-between items-center border border-white/50 hover:border-white text-white/50 hover:text-white rounded-lg p-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                                    </svg>
                                                    {{ $page.props.auth.user.first_name }}
                                                    <svg
                                                        class="ms-2 -me-0.5 h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                </div>

                                                </button>
                                            </span>
                                        </template>

                                        <template #content>
                                            <DropdownLink :href="route('user.profile')"> {{ $t('Profile') }} </DropdownLink>
                                            <DropdownLink :href="route('logout')" method="post" as="button">
                                                {{ $t('Log Out') }}
                                            </DropdownLink>
                                        </template>
                                    </Dropdown>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>